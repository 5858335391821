<template>
  <v-app>
    <v-app-bar app absolute height="90" color="white">
      <v-container>
        <v-row justify="center" justify-md="start" no-gutters>
          <div>
            <v-img
              alt=""
              max-height="58"
              max-width="230"
              :src="require('./assets/logo.png')"
            />
          </div>

          <div class="ml-5 justify-center flex-column d-none d-md-flex">
            <strong>{{ $options.config.HEADER_TITLE }}</strong>
            <div>{{ $options.config.HEADER_SUB_TITLE }}</div>
          </div>
        </v-row>
      </v-container>
    </v-app-bar>

    <v-main>
      <v-container>
        <v-stepper
          v-model="stepper"
          elevation="0"
          class="mt-5 mb-15 mis-stepper"
        >
          <v-stepper-header class="px-1">
            <v-stepper-step
              step="1"
              editable
              :edit-icon="'$complete'"
              :complete="stepper > 1"
            >
              Специальность
            </v-stepper-step>
            <v-stepper-step step="2" :complete="stepper > 2">
              Услуга
            </v-stepper-step>
            <v-stepper-step
              step="3"
              editable
              :edit-icon="'$complete'"
              :complete="stepper > 3"
            >
              Врач
            </v-stepper-step>
            <v-stepper-step step="4" :complete="stepper > 4">
              Регистрация
            </v-stepper-step>
            <v-stepper-step step="5"> Печать талона </v-stepper-step>
          </v-stepper-header>
          <v-stepper-items>
            <v-stepper-content step="1">
              <mis-specialties></mis-specialties>
            </v-stepper-content>
            <v-stepper-content step="3">
              <mis-doctors
                v-if="stepper === 3 || stepper === '3'"
                @next="$store.commit('setStep', 4)"
              ></mis-doctors>
            </v-stepper-content>
            <v-stepper-content step="4">
              <mis-registration></mis-registration>
            </v-stepper-content>
            <v-stepper-content step="5">
              <mis-result></mis-result>
            </v-stepper-content>
          </v-stepper-items>
        </v-stepper>

        <div
          class="text-center"
          style="max-width: 920px; margin: 0 auto"
          v-html="$options.config.SITE_WARN_TEXT_BOTTOM"
        ></div>
      </v-container>
    </v-main>

    <v-overlay :value="isLoading" z-index="203">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
  </v-app>
</template>

<script>
import MisDoctors from "./components/MisDoctors.vue";
import MisSpecialties from "./components/MisSpecialties.vue";
import { mapState } from "vuex";
import MisRegistration from "./components/MisRegistration.vue";
import MisResult from "./components/MisResult.vue";

export default {
  name: "App",

  components: { MisDoctors, MisSpecialties, MisRegistration, MisResult },

  config: {
    SITE_WARN_TEXT_BOTTOM: window.SITE_WARN_TEXT_BOTTOM,
    HEADER_TITLE: window.HEADER_TITLE,
    HEADER_SUB_TITLE: window.HEADER_SUB_TITLE,
  },

  computed: {
    ...mapState({
      isLoading: (state) => state.isLoading,
    }),
    stepper: {
      get() {
        return this.$store.state.stepper;
      },
      set(value) {
        return this.$store.commit("setStep", value);
      },
    },
  },

  watch: {
    stepper() {
      this.$vuetify.goTo(".v-application", { offset: 0 });
    },
  },

  mounted() {
    this.$store.dispatch("loadOptions").then(() => {
      this.$store.dispatch("loadBranches");
      this.$store.dispatch("loadSpecialties");
    });
  },
};
</script>

<style lang="scss">
@import "styles/main";
</style>
