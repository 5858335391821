<template>
  <div class="d-flex align-center flex-column pt-5">
    <div class="text-h5 mb-5" v-if="$vuetify.breakpoint.smAndUp">
      Укажите ваши данные для поиска подходящего врача
    </div>
    <div
      class="d-flex align-center mb-11"
      v-if="
        $store.state.options.hide_sex_and_age !== '1' &&
        $vuetify.breakpoint.smAndUp
      "
    >
      <div class="mr-5">Пол</div>
      <v-btn-toggle v-model="gender" background-color="transparent" tile>
        <v-btn class="mx-2 mis-gender-btn" x-large color="white">
          <img
            alt=""
            height="22"
            width="24"
            class="mr-2"
            :src="require('../assets/men.svg')"
          />
          Мужской
        </v-btn>
        <v-btn class="mx-2 mis-gender-btn" color="white" x-large>
          <img
            alt=""
            height="22"
            width="24"
            class="mr-2"
            :src="require('../assets/women.svg')"
          />
          Женский
        </v-btn>
      </v-btn-toggle>
      <div class="ml-5 mr-2">Возраст</div>
      <v-select
        :items="selectAgeList"
        v-model="age"
        label="-"
        solo
        hide-details="auto"
        style="max-width: 120px"
      ></v-select>
    </div>
    <div class="text-h5 mb-5">Выберите специальность</div>
    <div class="Specialty">
      <div
        v-for="specialty in specialties"
        :key="specialty.id"
        class="Specialty__item"
        @click="showSpecialtyDoctors(specialty)"
      >
        <div class="Specialty__title">{{ specialty.name }}</div>
        <img :src="require('../assets/ar_card.svg')" alt="" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { GENDERS_BY_INDEX } from "../constants";
export default {
  name: "MisSpecialties",
  data() {
    return {
      gender: null,
      age: -1,
    };
  },
  computed: {
    ...mapState({
      specialties: (state) => state.specialties,
    }),
    selectAgeList() {
      return Array.from(Array(100).keys());
    },
    genderId() {
      if (!this.gender && this.gender !== 0) return 0;
      return GENDERS_BY_INDEX[this.gender];
    },
  },
  watch: {
    gender() {
      this.$store.dispatch("loadFilteredSpecialties", {
        gender: this.genderId,
        age: this.age,
      });
    },
    age() {
      this.$store.dispatch("loadFilteredSpecialties", {
        gender: this.genderId,
        age: this.age,
      });
    },
  },
  methods: {
    showSpecialtyDoctors(specialty) {
      this.$store.commit("setActiveSpecialty", specialty.id);
      this.$store.commit("setStep", 3);
    },
  },
};
</script>
