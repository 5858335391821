import Vue from "vue";
import Vuex from "vuex";
import moment from "moment";
import { api } from "../api";
import { OptionsModel, TicketModel } from "./models";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    stepper: 1,
    isLoading: false,
    options: OptionsModel({}),
    branches: [],
    specialties: [],
    ticket: TicketModel({}),
  },
  getters: {
    getActiveSpecialtyName(state) {
      const activeItem = state.specialties.filter(
        (item) => item.id === state.ticket.specialtyId
      );
      if (!activeItem.length > 0) return "";
      return activeItem[0].name;
    },
    getActiveTicketInfo(state) {
      return `${state.ticket.doctorName}, ${moment(state.ticket.dateFrom)
        .locale("ru")
        .format("DD MMMM YYYY")} — ${moment(state.ticket.dateFrom).format(
        "HH:mm"
      )}`;
    },
  },
  mutations: {
    updateOptions(state, options) {
      state.options = OptionsModel(options);
    },
    updateBranches(state, branches = []) {
      state.branches = branches;
    },
    updateSpecialties(state, specialties = []) {
      state.specialties = specialties;
    },
    setLoading(state, isLoading = true) {
      state.isLoading = isLoading;
    },
    setActiveSpecialty(state, id) {
      state.ticket.specialtyId = id;
    },
    setStep(state, step) {
      state.stepper = step;
    },
    updateTicket(state, ticket) {
      state.ticket = {
        ...state.ticket,
        ...ticket,
      };
    },
  },
  actions: {
    loadOptions({ commit }) {
      commit("setLoading");
      return api
        .getOptions()
        .then((res) => {
          commit("updateOptions", res);
        })
        .finally(() => commit("setLoading", false));
    },
    loadBranches({ commit }) {
      api.getBranches().then((res) => {
        commit("updateBranches", res);
      });
    },
    loadSpecialties({ commit }) {
      commit("setLoading");
      api
        .getSpecialty()
        .then((res) => {
          commit("updateSpecialties", res);
        })
        .finally(() => commit("setLoading", false));
    },
    loadFilteredSpecialties({ commit }, { gender, age }) {
      commit("setLoading");
      api
        .getSpecialtyFilter(gender, age)
        .then((res) => {
          commit("updateSpecialties", res);
        })
        .finally(() => commit("setLoading", false));
    },
  },
  modules: {},
});
