<template>
  <v-card class="d-flex doctor-item">
    <div class="doctor-item__info-block">
      <div class="d-flex align-start flex-grow-1">
        <v-img
          class="doctor-item__image"
          position="top center"
          :src="
            doctor.photo_base64
              ? doctor.photo_base64
              : require('../assets/blank.jpg')
          "
        ></v-img>
        <div>
          <v-card-title class="pt-0 text-break">
            {{ doctor.name }}
          </v-card-title>
          <v-card-subtitle class="grey--text">
            {{ specializationName }}
          </v-card-subtitle>
          <v-card-text class="pb-0 grey--text">
            <long-text :text="doctor.description"></long-text>
          </v-card-text>
        </div>
      </div>
      <div class="doctor-item__actions">
        <div class="doctor-item__sub-text mb-2">
          Нажмите для выбора даты и времени
        </div>
        <v-btn
          color="primary"
          :x-large="$vuetify.breakpoint.mdAndUp"
          :loading="loadingTimeTable"
          @click="toggleTimeTable"
        >
          Показать расписание
          <v-icon class="ml-md-2 ml-auto">
            {{ show ? "mdi-chevron-up" : "mdi-chevron-down" }}
          </v-icon>
        </v-btn>
      </div>
    </div>
    <v-expand-transition>
      <div v-show="show">
        <v-card-text class="pt-0 px-2 px-md-5">
          <div class="doctor-item__body">
            <div class="mb-3">
              <v-icon class="mr-2"> mdi-map-marker-outline </v-icon> Выберите
              адрес:
            </div>
            <div
              :style="{
                'max-width': $vuetify.breakpoint.mdAndUp ? '300px' : '100%',
              }"
            >
              <v-select
                v-model="selectedBranch"
                :items="branchesAddresses"
                no-data-text="Нет данных"
                placeholder="Нет данных"
                solo
              ></v-select>
              <v-menu
                v-model="calendarMenu"
                :close-on-content-click="false"
                :nudge-right="$vuetify.breakpoint.mdAndUp ? 40 : 0"
                offset-y
                min-width="auto"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="getFormattedPeriod"
                    label="Выберите период"
                    prepend-icon="mdi-calendar"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker
                  v-model="calendarPeriod"
                  no-title
                  locale="ru-RU"
                  :min="dateFromToday(0)"
                  color="#2782cc"
                  range
                  @change="onChangeCalendarHandler"
                ></v-date-picker>
              </v-menu>
            </div>
            <time-table
              v-if="timeTableList.length > 0"
              :items="timeTableByBranch"
              :loadingMoreBtn="loadingMoreBtn"
              @load-more="loadMoreHandler"
              @click-time="$emit('click-time', $event)"
            ></time-table>
            <div
              v-else
              class="subtitle-2"
              v-html="$options.config.NO_TIME_TABLE_TEXT"
            ></div>
            <v-overlay absolute :value="loadingTimeTable">
              <v-progress-circular
                indeterminate
                :size="$vuetify.breakpoint.mdAndUp ? 64 : 32"
              ></v-progress-circular>
            </v-overlay>
          </div>
        </v-card-text>
      </div>
    </v-expand-transition>
  </v-card>
</template>

<script>
import LongText from "./LongText";
import { api } from "../api";
import { TimeTableManager } from "../services/TimeTableManager";
import moment from "moment";
import TimeTable from "./TimeTable/TimeTable.vue";
export default {
  name: "DoctorItem",
  components: { LongText, TimeTable },
  props: { doctor: Object, specializationName: String },
  config: {
    NO_TIME_TABLE_TEXT: window.NO_TIME_TABLE_TEXT,
  },
  data: () => ({
    show: false,
    loadingTimeTable: false,
    loadingMoreBtn: false,
    timeTableList: [],
    selectedBranch: null,
    calendarMenu: false,
    calendarPeriod: [
      moment().format("YYYY-MM-DD"),
      moment().format("YYYY-MM-DD"),
    ],
  }),
  computed: {
    getFormattedPeriod() {
      return `с ${moment(this.calendarPeriod[0]).format(
        "DD.MM.YYYY"
      )} по ${moment(this.calendarPeriod[1]).format("DD.MM.YYYY")}`;
    },
    branchesAddresses() {
      if (!(this.timeTableList.length > 0)) return [];
      const branches = this.$store.state.branches.filter(
        (branch) =>
          this.timeTableList.filter((day) => day.branchId === branch.id)
            .length > 0
      );
      if (branches.length > 1) branches.unshift({ address: "Все", id: 0 });
      return branches.map((item) => {
        return { text: item.address, value: item.id };
      });
    },
    timeTableByBranch() {
      if (!this.selectedBranch) return this.timeTableList;
      return this.timeTableList.filter(
        (day) => day.branchId === this.selectedBranch
      );
    },
  },
  methods: {
    dateFromToday(n) {
      return moment().add(n, "day").toISOString();
    },
    getFormattedDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },
    getEmployeeIds() {
      return Array.isArray(this.doctor.employeeid)
        ? this.doctor.employeeid
        : [this.doctor.employeeid];
    },
    async toggleTimeTable(e) {
      if (this.show) return (this.show = false);

      this.loadingTimeTable = true;
      this.calendarPeriod = [
        this.dateFromToday(0),
        this.dateFromToday(this.$store.state.options.doctor_period),
      ];
      const data = await api.getDoctor(
        this.getEmployeeIds(),
        this.getFormattedDate(this.calendarPeriod[0]),
        this.getFormattedDate(this.calendarPeriod[1]),
        this.doctor.employee_dto.specializationid
      );
      this.timeTableList = TimeTableManager.generateTimeTableList(data);
      this.loadingTimeTable = false;

      if (this.branchesAddresses[0])
        this.selectedBranch = this.branchesAddresses[0].value;

      this.show = true;
      this.$vuetify.goTo(e.target, {
        offset: this.$vuetify.breakpoint.mdAndUp ? -200 : 0,
      });
    },
    async loadMoreHandler() {
      const newStartDate = moment(this.calendarPeriod[1])
        .add(1, "day")
        .toISOString();
      this.calendarPeriod = [
        this.calendarPeriod[0],
        moment(newStartDate)
          .add(this.$store.state.options.doctor_period, "day")
          .toISOString(),
      ];

      this.loadingMoreBtn = true;
      const data = await api.getDoctor(
        this.getEmployeeIds(),
        this.getFormattedDate(newStartDate),
        this.getFormattedDate(this.calendarPeriod[1])
      );
      const loadedData = TimeTableManager.generateTimeTableList(data);
      if (loadedData.length > 0) {
        loadedData.map((item) => {
          this.timeTableList.push(item);
        });
      }
      this.loadingMoreBtn = false;
    },
    async onChangeCalendarHandler() {
      this.calendarMenu = false;
      this.loadingTimeTable = true;
      try {
        const data = await api.getDoctor(
          this.getEmployeeIds(),
          this.getFormattedDate(this.calendarPeriod[0]),
          this.getFormattedDate(this.calendarPeriod[1])
        );
        this.timeTableList = TimeTableManager.generateTimeTableList(data);
      } catch (e) {
        alert("Произошла ошибка!");
      } finally {
        this.loadingTimeTable = false;
      }
    },
  },
};
</script>

<style lang="scss">
.doctor-item {
  flex-direction: column;
  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    .v-card__title {
      font-size: 16px;
      line-height: 1.5;
    }
    .v-card__subtitle,
    .v-card__text {
      font-size: 14px;
      line-height: 1.5;
    }
  }
  &__info-block {
    width: 100%;
    display: flex;
    align-items: flex-start;
    padding: 20px;
    .v-card__title {
      font-size: 16px;
      line-height: 1.4;
    }
    .v-card__subtitle {
      font-size: 14px;
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      flex-direction: column;
      & > div {
        width: 100%;
      }
      padding: 16px 8px;
    }
  }
  &__image {
    max-width: 130px !important;
    min-height: 110px !important;
    max-height: 110px !important;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      max-width: 30% !important;
    }
  }
  &__actions {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      width: 100%;
      text-align: center;
      margin-top: 10px;
    }
  }
  &__sub-text {
    font-size: 14px;
    color: $mis-gray-text;
  }
  &__body {
    background-color: $mis-background;
    padding: 16px 24px;
    border-radius: 6px;
    position: relative;
    &:after {
      content: " ";
      width: 20px;
      height: 20px;
      top: -10px;
      position: absolute;
      background: inherit;
      right: 28px;
      transform: rotate(45deg);
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      padding: 16px 8px;
    }
  }
}
</style>
