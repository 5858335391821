import axios from "axios";
import moment from "moment";
import { getApiUrl, getApiToken } from "../constants";

const api_endpoints = {
  options: "options.get.v1",
  specialty: "cab_specializations.get.v1",
  doctor_by_specialty: "cab_employees_by_specialization.get.v1",
  get_doctor_shedule: "cab_employees_timetable.get.v1",
  validate_phone: "validate_phone_by_sms.v1",
  gender_age: "cab_specializations_by_gender_and_age.get.v1",
  branches: "branches_requisites.get.v1",
  appointment_create: "appointment_create.v1",
};

const api_axios = axios.create();

export const api = {
  getOptions: async function () {
    let { data } = await api_axios.post(getApiUrl() + api_endpoints.options, {
      token: getApiToken(),
    });
    return JSON.parse(data).data;
  },
  getBranches: async function () {
    let { data } = await api_axios.post(getApiUrl() + api_endpoints.branches, {
      token: getApiToken(),
    });
    return data;
  },
  getSpecialty: async function () {
    let { data } = await api_axios.post(getApiUrl() + api_endpoints.specialty, {
      token: getApiToken(),
    });
    return data;
  },
  getSpecialtyFilter: async function (gender, age = -1) {
    let { data } = await api_axios.post(
      getApiUrl() + api_endpoints.gender_age,
      {
        token: getApiToken(),
        gender,
        age,
      }
    );
    return data;
  },
  getDoctors: async function (specializationid) {
    let { data } = await api_axios.post(
      getApiUrl() + api_endpoints.doctor_by_specialty,
      { token: getApiToken(), specializationid }
    );
    return data;
  },
  getDoctor: async function (
    doctorId,
    dateFrom,
    dateTo,
    specializationId = null
  ) {
    let { data } = await api_axios.post(
      getApiUrl() + api_endpoints.get_doctor_shedule,
      {
        token: getApiToken(),
        date_from: dateFrom,
        date_to: dateTo,
        employee_ids: doctorId,
        specialization_ids: specializationId ? [specializationId] : [],
      }
    );
    data = data.map((item) => {
      return {
        data: item.data.sort((a, b) => {
          return moment(a.date).isAfter(b.date);
        }),
        employeeid: item.employeeid,
      };
    });
    return data;
  },
  create: function (appointmentData) {
    return api_axios.post(getApiUrl() + api_endpoints.appointment_create, {
      ...appointmentData,
      token: getApiToken(),
    });
  },
  validatePhone: async function (appointmentData, code = "") {
    let data = await api_axios.post(
      getApiUrl() + api_endpoints.validate_phone,
      {
        ...appointmentData,
        code,
        token: getApiToken(),
      }
    );
    return data.data;
  },
};
