export const REG_PHONE = window.REG_PHONE | "";
export const API_URL = window.API_URL;
export const API_TOKEN = window.API_TOKEN;

export const getApiUrl = () => API_URL;
export const getApiToken = () => API_TOKEN;

export const GENDERS = {
  MALE: 1,
  FEMALE: 2,
};

export const GENDERS_BY_INDEX = [GENDERS.MALE, GENDERS.FEMALE];
