<template>
  <tr>
    <td>{{ dayPartName }}</td>
    <td v-for="day in timeTableList" :key="day.date">
      <div class="time-table-list">
        <v-chip
          class="time-table-item mr-2 mb-2"
          v-for="time in day.dayParts[dayPartId]"
          :key="time.id"
          @click="onClickTimeHandler(time)"
          color="white"
          label
          small
        >
          {{ getFormattedTime(time.start) }}
        </v-chip>
      </div>
    </td>
    <td class="text-center">
      <v-btn
        v-if="$vuetify.breakpoint.mdAndUp && dayPartId === 'd2'"
        :loading="loadingMoreBtn"
        color="primary"
        @click="$emit('load-more')"
      >
        Загрузить ещё
      </v-btn>
    </td>
  </tr>
</template>

<script>
import moment from "moment";
export default {
  name: "TimeTableRow",
  props: {
    timeTableList: Array,
    dayPartName: String,
    dayPartId: String,
    loadingMoreBtn: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    getFormattedTime(date) {
      return moment(date).format("HH:mm");
    },
    onClickTimeHandler(time) {
      return this.$emit("click-time", time);
    },
  },
};
</script>
