<template>
  <v-dialog v-model="smsDialog" width="600px">
    <v-card>
      <v-card-title>
        <v-spacer></v-spacer>
        <v-icon class="mr-n4 mr-md-0" @click="smsDialog = false">
          mdi-close
        </v-icon>
      </v-card-title>
      <v-card-text>
        <v-text-field
          label="Код подтверждения"
          outlined
          v-model="code"
        ></v-text-field>
        <div class="d-flex align-center">
          <v-btn color="primary" @click="sendCode" :disabled="code === ''">
            Отправить
          </v-btn>
          <span class="ml-2">Введите код из смс: {{ countdown }}</span>
          <v-btn color="primary" text @click="resendSms" v-if="showResendBtn">
            Прислать код повторно
          </v-btn>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { api } from "../api";
export default {
  name: "ValidatePhoneForm",
  timer: null,
  dialogController: null,
  data() {
    return {
      smsDialog: false,
      countdown: "",
      showResendBtn: false,
      code: "",
      appointmentData: {},
    };
  },
  watch: {
    smsDialog() {
      if (!this.smsDialog) {
        //if close dialog - clear
        clearInterval(this.$options.timer);
        window.localStorage.removeItem("timer");

        this.$options.dialogController.reject(false);
      }
    },
  },
  methods: {
    startCountdown() {
      //todo: old version of countdown
      window.localStorage.setItem("timer", 300);

      this.$options.timer = setInterval(() => {
        let curTime = +window.localStorage.getItem("timer");

        let min = Math.floor(curTime / 60);
        let sec = curTime % 60;

        sec = String(sec).length == 1 ? "0" + sec : sec;

        this.countdown = `${min}:${sec}`;

        if (curTime > 0) {
          curTime--;
          window.localStorage.setItem("timer", curTime);
        } else {
          this.showResendBtn = true;
          clearInterval(this.$options.timer);
        }
      }, 1000);
    },
    validatePhone(appointmentData) {
      let resolve;
      let reject;
      const dialogPromise = new Promise((ok, fail) => {
        resolve = ok;
        reject = fail;
      });
      this.$options.dialogController = { resolve, reject };

      this.sendValidation(appointmentData).then(() => {
        this.smsDialog = true;
        this.code = "";
        this.appointmentData = appointmentData;
        this.startCountdown();
      });

      return dialogPromise;
    },
    resendSms() {
      this.showResendBtn = false;
      this.startCountdown();
      this.sendValidation(this.appointmentData);
    },
    sendCode() {
      this.sendValidation(this.appointmentData, this.code);
    },
    sendValidation(appointmentData, code = "") {
      return new Promise((ok) => {
        api
          .validatePhone(appointmentData, code)
          .then((res) => {
            if (res.error_messages) {
              alert(res.error_messages);
            } else if (res.wait_for_code === 0 && res.status) {
              this.smsDialog = false;
              this.$options.dialogController.resolve(true);
            }
            ok();
          })
          .catch((err) => {
            if (this.smsDialog) return (this.smsDialog = false);
            return this.$options.dialogController.reject(err);
          });
      });
    },
  },
};
</script>
