<template>
  <div>
    <div class="mb-10 text-subtitle-2">
      Вы выбрали: {{ $store.getters.getActiveSpecialtyName }}
      {{ $store.getters.getActiveTicketInfo }}
    </div>
    <h2 class="text-h5 text-center mb-10">Зарегистрируйтесь</h2>
    <v-card max-width="740px" style="margin: 0 auto">
      <v-card-text class="pa-7">
        <v-form
          ref="registerForm"
          v-model="registerFormValid"
          lazy-validation
          @keyup.native.enter="registerFormSubmit"
          class="pt-3"
        >
          <v-row>
            <v-col cols="12" md="6" class="py-0">
              <div class="form-group">
                <div class="form-group__label text-label">
                  Фамилия <span class="red--text">*</span>
                </div>
                <v-text-field
                  v-model="registerForm.patient_lastname"
                  :rules="registerFormRules.patient_lastname"
                  placeholder="Фамилия"
                  dense
                  outlined
                  required
                >
                </v-text-field>
              </div>
              <div class="form-group">
                <div class="form-group__label text-label">
                  Имя <span class="red--text">*</span>
                </div>
                <v-text-field
                  v-model="registerForm.patient_firstname"
                  :rules="registerFormRules.patient_firstname"
                  placeholder="Имя"
                  dense
                  outlined
                  required
                >
                </v-text-field>
              </div>
              <div class="form-group">
                <div class="form-group__label text-label">
                  Отчество <span class="red--text">*</span>
                </div>
                <v-text-field
                  v-model="registerForm.patient_middlename"
                  :rules="registerFormRules.patient_middlename"
                  placeholder="Отчество"
                  dense
                  outlined
                  required
                >
                </v-text-field>
              </div>
            </v-col>
            <v-col cols="12" md="6" class="py-0">
              <div class="form-group">
                <div
                  class="form-group__label text-label mr-0 mr-sm-2"
                  style="min-width: 160px"
                >
                  Дата рождения <v-icon> mdi-calendar-month </v-icon>
                  <span class="red--text">*</span>
                </div>
                <v-text-field
                  v-model="registerForm.patient_birthday"
                  :rules="registerFormRules.patient_birthday"
                  placeholder="dd.mm.yyyy"
                  v-mask="'##.##.####'"
                  dense
                  outlined
                  required
                >
                </v-text-field>
              </div>
              <div class="form-group">
                <div class="form-group__label text-label">
                  Телефон <span class="red--text">*</span>
                </div>
                <v-text-field
                  v-model="registerForm.phone_number"
                  :rules="registerFormRules.phone_number"
                  placeholder="+7________"
                  v-mask="'+7##########'"
                  dense
                  outlined
                  required
                >
                </v-text-field>
              </div>
              <div class="form-group">
                <div class="form-group__label text-label">
                  Email
                  <span
                    v-if="$store.state.options.email_required === '1'"
                    class="red--text"
                    >*</span
                  >
                </div>
                <v-text-field
                  v-model="registerForm.patient_email"
                  :rules="registerFormRules.patient_email"
                  placeholder="example@email.com"
                  dense
                  outlined
                  required
                >
                </v-text-field>
              </div>
            </v-col>
            <v-col class="py-0" cols="12">
              <div class="form-group">
                <div class="form-group__label text-label mr-0 mr-sm-4">
                  Комментарий
                </div>
                <v-textarea
                  v-model="registerForm.appointment_comment"
                  height="100px"
                  dense
                  outlined
                ></v-textarea>
              </div>
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
              <v-checkbox
                v-model="registerAgree"
                hide-details="auto"
                :rules="registerFormRules.registerAgree"
              >
                <template v-slot:label>
                  <span class="text-body-2 font-weight-600">
                    Нажимая кнопку "Записаться на прием" я
                    <a
                      href="#"
                      class="primary--text"
                      @click.stop.prevent="agreeDialog = true"
                    >
                      даю согласие на обработку персональных данных
                    </a>
                  </span>
                </template>
              </v-checkbox>
              <v-checkbox
                v-model="registerSmsAgree"
                hide-details="auto"
                :rules="registerFormRules.registerSmsAgree"
              >
                <template v-slot:label>
                  <span class="text-body-2 font-weight-600">
                    Нажимая кнопку "Записаться на прием" я даю
                    <a
                      href="#"
                      class="primary--text"
                      @click.stop.prevent="agreeSmsDialog = true"
                    >
                      согласие на получение смс рассылки
                    </a>
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col cols="12" md="6" class="pt-0">
              <v-btn
                color="primary"
                x-large
                block
                class="mt-8"
                @click="registerFormSubmit"
                :disabled="!registerFormValid"
                :loading="loadingForm"
              >
                Записаться на прием
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
    <validate-phone-form ref="validatePhoneForm"></validate-phone-form>
    <v-dialog v-model="agreeDialog" width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"> {{ $options.config.agreeTitle }} </span>
        </v-card-title>
        <v-card-text v-html="$options.config.agreeText"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="agreeDialog = false"> Закрыть </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="agreeSmsDialog" width="600px">
      <v-card>
        <v-card-title>
          <span class="text-h5"> {{ $options.config.agreeSmsTitle }} </span>
        </v-card-title>
        <v-card-text v-html="$options.config.agreeSmsText"></v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="agreeSmsDialog = false">
            Закрыть
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { api } from "../api";
import { AppointmentModel } from "../store/models";
import ValidatePhoneForm from "./ValidatePhoneForm.vue";
export default {
  components: { ValidatePhoneForm },
  name: "MisRegistration",
  config: {
    agreeTitle: window.AGREE_TITLE,
    agreeText: window.AGREE_TEXT,
    agreeSmsTitle: window.AGREE_SMS_TITLE,
    agreeSmsText: window.AGREE_SMS_TEXT,
  },
  data() {
    return {
      agreeDialog: false,
      agreeSmsDialog: false,
      registerFormValid: true,
      registerAgree: true,
      registerSmsAgree: true,
      loadingForm: false,
      registerForm: {
        patient_lastname: "",
        patient_firstname: "",
        patient_middlename: "",
        patient_birthday: "",
        phone_number: "",
        patient_email: "",
        appointment_comment: "",
      },
      registerFormRules: {
        patient_lastname: [this.requiredRule],
        patient_firstname: [this.requiredRule],
        patient_middlename: [this.requiredRule],
        patient_birthday: [this.requiredRule],
        phone_number: [
          this.requiredRule,
          (v) =>
            (v && v.length >= 12) ||
            "В этом поле должно быть не менее 12 символов.",
          (v) =>
            (v && v.length <= 12) ||
            "В этом поле должно быть не более 12 символов.",
        ],
        registerAgree: [this.requiredRule],
        registerSmsAgree: [this.requiredRule],
        patient_email: [
          (v) => {
            if (!v && this.$store.state.options.email_required === "0")
              return true;
            return /.+@.+\..+/.test(v) || "Некорректный Email";
          },
        ],
      },
    };
  },
  methods: {
    requiredRule: (v) => !!v || "Обязательное поле",
    registerFormSubmit() {
      if (!this.$refs.registerForm.validate()) return false;

      const appointmentData = AppointmentModel({
        employeeid: this.$store.state.ticket.doctorId,
        appointment_datefrom: this.$store.state.ticket.dateFrom,
        appointment_dateto: this.$store.state.ticket.dateTo,
        appointment_scheduleid: this.$store.state.ticket.scheduleid,
        ...this.registerForm,
      });

      if (this.$store.state.options.verify_sms === true) {
        this.loadingForm = true;
        this.$refs.validatePhoneForm
          .validatePhone(appointmentData)
          .then(() => {
            this.loadingForm = false;
            //если все ок телефон активирован то создаем талон
            this.create(appointmentData);
          })
          .catch((err) => {
            this.loadingForm = false;
            if (
              err.response &&
              err.response.data.includes("Некорректно передан параметр")
            ) {
              alert("Ошибка валидации");
            } else {
              alert("Что-то пошло не так!");
            }
          });
      } else {
        this.create(appointmentData);
      }
    },
    create(appointmentData) {
      this.loadingForm = true;
      api
        .create(appointmentData)
        .then(() => {
          this.$store.commit("setStep", 5);
          this.$refs.registerForm.reset();
        })
        .catch((err) => {
          console.log(err.response ? err.response : err);
          let customText = "";
          if (err.response) {
            if (err.response.data.includes("Данное время записи занято")) {
              customText = "Данное время записи занято!";
            }
            if (err.response.data.includes("Некорректно передан параметр")) {
              customText = "Некорректная валидация!";
            }
          }
          alert("Произошла ошибка! \n" + customText);
        })
        .finally(() => (this.loadingForm = false));
    },
  },
};
</script>
