<template>
  <div class="time-table">
    <table v-if="$vuetify.breakpoint.mdAndUp">
      <tbody>
        <tr class="filled-bg">
          <td class="text-capitalize">{{ tableMonthName }}</td>
          <td
            class="time-table-header__td text-capitalize"
            v-for="day in items"
            :key="day.date"
          >
            {{ getFormattedDayByDate(day.date) }}
          </td>
          <td></td>
        </tr>
        <time-table-row
          :timeTableList="items"
          dayPartName="Утром"
          dayPartId="d1"
          @click-time="$emit('click-time', $event)"
        ></time-table-row>
        <time-table-row
          :timeTableList="items"
          dayPartName="Днем"
          dayPartId="d2"
          :loadingMoreBtn="loadingMoreBtn"
          @load-more="$emit('load-more')"
          @click-time="$emit('click-time', $event)"
        ></time-table-row>
        <time-table-row
          :timeTableList="items"
          dayPartName="Вечером"
          dayPartId="d3"
          @click-time="$emit('click-time', $event)"
        ></time-table-row>
      </tbody>
    </table>
    <template v-else>
      <div class="time-table-days">
        <v-chip-group
          mandatory
          active-class="primary--text"
          v-model="activeDayIndex"
        >
          <v-chip
            v-for="day in items"
            :key="day.date"
            color="white"
            class="time-table-days__item"
            label
            large
          >
            {{ getFormattedDayByDate(day.date).split(", ")[0] }} <br />
            {{ getFormattedDayByDate(day.date).split(", ")[1] }}
          </v-chip>
          <v-btn
            color="primary"
            class="mt-4"
            :loading="loadingMoreBtn"
            @click.stop.prevent="$emit('load-more')"
          >
            <v-icon> mdi-plus </v-icon>
          </v-btn>
        </v-chip-group>
        <div class="my-3">
          <v-icon class="mr-2"> mdi-clock-time-two-outline </v-icon> Выберите
          удобное время:
        </div>
        <table>
          <time-table-row
            :timeTableList="[items[activeDayIndex]]"
            dayPartName="Утром"
            dayPartId="d1"
            @click-time="$emit('click-time', $event)"
          ></time-table-row>
          <time-table-row
            :timeTableList="[items[activeDayIndex]]"
            dayPartName="Днем"
            dayPartId="d2"
            @click-time="$emit('click-time', $event)"
          ></time-table-row>
          <time-table-row
            :timeTableList="[items[activeDayIndex]]"
            dayPartName="Вечером"
            dayPartId="d3"
            @click-time="$emit('click-time', $event)"
          ></time-table-row>
        </table>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import TimeTableRow from "./TimeTableRow";
export default {
  name: "TimeTable",
  components: { TimeTableRow },
  props: {
    items: Array,
    loadingMoreBtn: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    activeDayIndex: 0,
  }),
  computed: {
    tableMonthName() {
      return moment(this.items[0].date).locale("ru").format("MMMM");
    },
  },
  methods: {
    getFormattedDayByDate(date) {
      return moment(date).locale("ru").format("dd, DD");
    },
  },
};
</script>

<style lang="scss">
$mis-table-border: #979797;
.time-table {
  overflow-x: auto;
  border: 1px solid $mis-table-border;
  border-radius: 4px;

  @media #{map-get($display-breakpoints, 'sm-and-down')} {
    border: none;
  }

  &-header {
    &__td {
      text-align: center;
    }
  }

  &-item {
    box-shadow: 0 3px 9px 0 rgba(0, 0, 0, 0.1);
    &.v-chip.v-size--small {
      height: 26px;
      padding: 0 6px;
      font-weight: 600;
      font-size: 14px;
    }
  }

  &-days {
    &__item {
      &:first-child {
        margin-left: 5px !important;
      }
      box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.6);
      font-weight: 600;
      &.v-chip.v-size--large {
        font-size: 14px;
      }
      &.v-chip--label {
        border-radius: 0 !important;
        border: 2px solid transparent !important;
      }
      &.v-chip--active {
        border: 2px solid currentColor !important;
        &:before {
          background-color: transparent;
        }
      }
    }
  }

  table {
    border-spacing: 0;
    transition: all 0.3s ease;
    left: 0;
    position: relative;
    width: 100%;
  }
  td {
    padding: 10px;
    font-size: 0.875rem;
    font-weight: 600;
    border-bottom: 1px dashed $mis-table-border;
    border-right: 1px solid $mis-table-border;
    min-width: 250px;
    &:first-child {
      min-width: auto;
      width: auto;
    }
    @media #{map-get($display-breakpoints, 'sm-and-down')} {
      min-width: auto;
      border: none;
    }
  }
  .filled-bg {
    background-color: $mis-table-border;
    color: #ffffff;
    td {
      &:first-child {
        width: auto;
        min-width: auto;
      }
    }
  }
}
</style>
