<template>
  <div>
    <h2 class="text-h5 text-center mb-10 mt-5 mt-md-10">Вы успешно записаны</h2>
    <v-card class="pa-5">
      <v-row class="align-center">
        <v-col cols="12" md="6">
          <div class="text-h6 font-weight-bold mb-5">
            Информация о вашем приеме:
          </div>
          <div class="mb-3">Доктор: {{ $store.state.ticket.doctorName }}</div>
          <div class="mb-3">
            Услуга: {{ $store.getters.getActiveSpecialtyName }}
          </div>
          <div class="mb-3">
            Дата: {{ $store.getters.getActiveTicketInfo.split(", ")[1] }}
          </div>
          <div>Адрес: {{ $store.state.ticket.address }}</div>
        </v-col>
        <v-col cols="12" md="6">
          <v-btn color="primary" x-large block class="mb-5" @click="print">
            Распечатать талон
          </v-btn>
          <v-btn color="primary" x-large @click="goStart" block>
            Записаться на прием
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "MisResult",
  methods: {
    goStart() {
      window.location.reload();
    },
    print() {
      let print_window = window.open("", "PRINT", "height=400,width=600");
      print_window.document.write("<!DOCTYPE html><html><body>");

      print_window.document.write(
        `<h4>Информация о вашем приеме:</h4><p>
            <strong>Доктор:</strong> <span id="doctor-fio">${
              this.$store.state.ticket.doctorName
            }</span></p><p>
            <strong>Дата:</strong> <span id="ticket-date">${
              this.$store.getters.getActiveTicketInfo.split(", ")[1]
            }</span></p><p>
            <strong>Адрес:</strong> <span id="doctor-address">${
              this.$store.state.ticket.address
            }</span></p></body></html>`
      );

      print_window.document.close();
      print_window.focus();

      print_window.print();
      print_window.close();

      return true;
    },
  },
};
</script>
