import moment from "moment";

class TimeTableManagerService {
  constructor() {}

  generateTimeTableList(data) {
    if (!data) return [];

    let timeTableList = [];

    /* где data в аргументе получаем список дней разделенный по клиникам(branch) */
    // todo: переименовать аргументы
    data.map(({ data }) => {
      /* где data список дней. в аргументе получаем data - список времени */
      data.map(({ data, date }) => {
        //todo: create check
        // if (!doctorItem.checkIsAllowedBranch(data[0].branchid)) return false;

        //todo: refactor to service
        const generatedPartsOfDay = this.generatePartsOfDay(data);
        /* исключить день если список времени суток пустой */
        if (this.isPartsOfDayEmpty(generatedPartsOfDay)) return false;

        if (this.checkIsTableHasDate(timeTableList, date)) {
          const dayIndex = timeTableList.findIndex((day) => day.date === date);
          timeTableList[dayIndex].dayParts = this.concatePartsOfDayList(
            timeTableList[dayIndex].dayParts,
            generatedPartsOfDay
          );
        } else {
          timeTableList.push({
            dayParts: generatedPartsOfDay,
            branchId: data[0].branchid,
            date: date,
          });
        }
      });
    });

    /* сортирует по дате */
    timeTableList = timeTableList.sort((a, b) =>
      moment(a.date).isAfter(b.date) ? 1 : -1
    );

    return timeTableList;
  }
  generatePartsOfDay(timesList) {
    let d1 = [],
      d2 = [],
      d3 = [];

    timesList.map((time) => {
      /* исключаем прошедшее время */
      if (moment().diff(time.start) > 0) return false;

      let hours = moment(time.start).hours(),
        mins = moment(time.start).minutes();

      if (hours <= 11) {
        d1.push(time);
      } else if (hours <= 14 && mins <= 30) {
        d2.push(time);
      } else {
        d3.push(time);
      }
    });

    return { d1, d2, d3 };
  }
  isPartsOfDayEmpty(dayPartsList) {
    return !Object.keys(dayPartsList).some(
      (dayPart) => dayPartsList[dayPart].length > 0
    );
  }
  checkIsTableHasDate(tableList, date) {
    return tableList.filter((tableItem) => tableItem.date === date).length > 0;
  }
  concatePartsOfDayList(currentPartsList, newPartsList) {
    return {
      d1: this.removeTheSamePartsOfDayTimes([
        ...currentPartsList.d1,
        ...newPartsList.d1,
      ]),
      d2: this.removeTheSamePartsOfDayTimes([
        ...currentPartsList.d2,
        ...newPartsList.d2,
      ]),
      d3: this.removeTheSamePartsOfDayTimes([
        ...currentPartsList.d3,
        ...newPartsList.d3,
      ]),
    };
  }
  removeTheSamePartsOfDayTimes(dayPartsList) {
    return dayPartsList.filter(
      (v, i, a) => a.findIndex((t) => t.start === v.start) === i
    );
  }
}

export const TimeTableManager = new TimeTableManagerService();
