<template>
  <div class="long-text" v-if="text">
    <div
      class="long-text__text"
      :class="{ short: hasLongText && isShort }"
      v-html="text"
    ></div>
    <v-btn
      v-if="hasLongText"
      text
      color="primary"
      class="ml-n3"
      small
      @click="isShort = !isShort"
    >
      {{ isShort ? "Показать все" : "Скрыть все" }}
    </v-btn>
  </div>
</template>

<script>
export default {
  name: "LongText",
  props: { text: String },
  computed: {
    hasLongText() {
      return this.text && this.text.length > 93;
    },
  },
  data: () => ({
    isShort: true,
  }),
};
</script>

<style lang="scss">
.long-text {
  &__text {
    font-size: 14px;
    &.short {
      display: -webkit-box !important;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 44px;
    }
  }
}
</style>
