var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"d-flex doctor-item"},[_c('div',{staticClass:"doctor-item__info-block"},[_c('div',{staticClass:"d-flex align-start flex-grow-1"},[_c('v-img',{staticClass:"doctor-item__image",attrs:{"position":"top center","src":_vm.doctor.photo_base64
            ? _vm.doctor.photo_base64
            : require('../assets/blank.jpg')}}),_c('div',[_c('v-card-title',{staticClass:"pt-0 text-break"},[_vm._v(" "+_vm._s(_vm.doctor.name)+" ")]),_c('v-card-subtitle',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.specializationName)+" ")]),_c('v-card-text',{staticClass:"pb-0 grey--text"},[_c('long-text',{attrs:{"text":_vm.doctor.description}})],1)],1)],1),_c('div',{staticClass:"doctor-item__actions"},[_c('div',{staticClass:"doctor-item__sub-text mb-2"},[_vm._v(" Нажмите для выбора даты и времени ")]),_c('v-btn',{attrs:{"color":"primary","x-large":_vm.$vuetify.breakpoint.mdAndUp,"loading":_vm.loadingTimeTable},on:{"click":_vm.toggleTimeTable}},[_vm._v(" Показать расписание "),_c('v-icon',{staticClass:"ml-md-2 ml-auto"},[_vm._v(" "+_vm._s(_vm.show ? "mdi-chevron-up" : "mdi-chevron-down")+" ")])],1)],1)]),_c('v-expand-transition',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.show),expression:"show"}]},[_c('v-card-text',{staticClass:"pt-0 px-2 px-md-5"},[_c('div',{staticClass:"doctor-item__body"},[_c('div',{staticClass:"mb-3"},[_c('v-icon',{staticClass:"mr-2"},[_vm._v(" mdi-map-marker-outline ")]),_vm._v(" Выберите адрес: ")],1),_c('div',{style:({
              'max-width': _vm.$vuetify.breakpoint.mdAndUp ? '300px' : '100%',
            })},[_c('v-select',{attrs:{"items":_vm.branchesAddresses,"no-data-text":"Нет данных","placeholder":"Нет данных","solo":""},model:{value:(_vm.selectedBranch),callback:function ($$v) {_vm.selectedBranch=$$v},expression:"selectedBranch"}}),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":_vm.$vuetify.breakpoint.mdAndUp ? 40 : 0,"offset-y":"","min-width":"auto"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
            var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Выберите период","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.getFormattedPeriod),callback:function ($$v) {_vm.getFormattedPeriod=$$v},expression:"getFormattedPeriod"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.calendarMenu),callback:function ($$v) {_vm.calendarMenu=$$v},expression:"calendarMenu"}},[_c('v-date-picker',{attrs:{"no-title":"","locale":"ru-RU","min":_vm.dateFromToday(0),"color":"#2782cc","range":""},on:{"change":_vm.onChangeCalendarHandler},model:{value:(_vm.calendarPeriod),callback:function ($$v) {_vm.calendarPeriod=$$v},expression:"calendarPeriod"}})],1)],1),(_vm.timeTableList.length > 0)?_c('time-table',{attrs:{"items":_vm.timeTableByBranch,"loadingMoreBtn":_vm.loadingMoreBtn},on:{"load-more":_vm.loadMoreHandler,"click-time":function($event){return _vm.$emit('click-time', $event)}}}):_c('div',{staticClass:"subtitle-2",domProps:{"innerHTML":_vm._s(_vm.$options.config.NO_TIME_TABLE_TEXT)}}),_c('v-overlay',{attrs:{"absolute":"","value":_vm.loadingTimeTable}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":_vm.$vuetify.breakpoint.mdAndUp ? 64 : 32}})],1)],1)])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }